// Overwrite Variables Here

$primary: #2ed300;
$secondary:#FF5C04;
$alt:#FD043A;

$primaryLight: #F3FCEE;
$primaryDark: #163606;

$sidebarBg: #232227;
$ribbonBg: #2ed300;

$link: #22a000;