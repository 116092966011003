@use 'sass:math';
@import "./src/styles/Library";
// heading block
.heading {
    text-transform: capitalize;
    padding-bottom: 5px;
}

.subHeading {
    text-transform: capitalize;
}

// image block
.imageText {
    padding: 10px;
}

// ContentContentSlider
.pad {
    padding-bottom: 5px;
}

.contentText {
    padding: 10px 10px;
    margin-bottom: 10px;
    background-color: $dark0;
    border-top: 1px solid $primary;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.contentTextHead {
    color: $primary;
}

.contentTextDesc {
    color: $light0;
}

.colWrap {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

// checklist
.checkWrap {
    border-radius: 9px;
    border: 1px solid #F6DE00;
    padding: 10px;
    background-color: rgba(#F6DE00, 0.2);
}

.headingCheck {
    padding-bottom: 5px;
    text-transform: uppercase;
}

.footerCheck {
    padding-top: 5px;
}

// spotlight
.spotCol {
    padding-bottom: 10px;
}

// static component
.staticCmp {
    background-color: rgba(#31bdf5, 0.2);
    border-radius: 9px;
    border: 1px solid #31bdf5;
    padding: 10px;
}